import React, { useState, useMemo } from "react";
import EODFileUploader from "./EODFileUploader";
import FileUploader from "./FileUploader";
import MultiFileUploader from "./MultiFileUploader";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MainFileUploader = () => {
  const [grossSales, setGrossSales] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [vatableSales, setVatableSales] = useState(0);
  const [nonVatSales, setNonVatSales] = useState(0);
  const [vatExemptSales, setVatExemptSales] = useState(0);
  const [vatExemptAmount, setVatExemptAmount] = useState(0);
  const [discounts, setDiscount] = useState(0);
  const [snrCitDisc, setSnrCitDisc] = useState(0);
  const [pwdDisc, setPwdDisc] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [voidAmount, setVoidAmount] = useState(0);
  const [servchargeAmount, setServchargeAmount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [cardAmount, setCardAmount] = useState(0);
  const [dCardAmount, setDcardAmount] = useState(0);
  const [ePayAmount, setEpayAmount] = useState(0);
  const [onlineSales, setOnlineSales] = useState(0);
  const [gcSales, setGcSales] = useState(0);
  const [checkSales, setCheckSales] = useState(0);
  const [foodPandaSales, setFoodPandaSales] = useState(0);
  const [grabSales, setGrabSales] = useState(0);
  const [rawGrossValue, setRawGrossValue] = useState(0);
  const [rawVatValue, setRawVatValue] = useState(0);
  const [headerNonVat, setHeaderNonVatValue] = useState(0);
  const [rawVatableSalesValue, setRawVatableSalesValue] = useState(0);
  const [headerServCharge, setHeaderServCharge] = useState(0);
  const [headerTotDisc, setHeaderTotDisc] = useState(0);
  const [headerTotRef, setHeaderTotRef] = useState(0);
  const [rawDlyValue, setRawDlyValue] = useState(0);
  const [multiFileResults, setMultiFileResults] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [transactions, setTransactions] = useState([]); // For TXT_ transactions
  const [csvTransactions, setCsvTransactions] = useState([]); // For CSV_ transactions

  const handleHeaderResults = ({
    headerData,
    rawGrossValue,
    rawVatValue,
    rawVatableSalesValue: dlySaleValue,
    headerDlySale,
    headerNonVat,
    rawDlyValue,
    headerServCharge,
    headerTotDisc,
    headerTotRef,
  }) => {
    setRawGrossValue(Number(rawGrossValue));
    setRawVatValue(Number(rawVatValue));
    setHeaderNonVatValue(Number(headerNonVat));
    setRawVatableSalesValue(Number(dlySaleValue - rawVatValue));
    setRawDlyValue(Number(rawDlyValue));
    setHeaderServCharge(Number(headerServCharge));
    setHeaderTotDisc(Number(headerTotDisc));
    setHeaderTotRef(Number(headerTotRef));
    setIsFileUploaded(true);
  };

  const handleDetailsResults = ({ transactions, salesSum, trancntSum }) => {
    setTransactions(transactions); // Set transactions in state
  };

  const handleMultiFileResults = (csvTransaction) => {
    console.log("Received results from MultiFileUploader:", csvTransaction);

    // Process CSV data and group by hour
    const groupedTransactions = {};

    csvTransaction.forEach((transaction) => {
      const {
        csvTime,
        vatAmount,
        vatableSales,
        vatExemptSales,
        csvDate,
        slsFlag,
      } = transaction;

      // Calculate sales amounts for S and R flags
      const csvSalesS =
        slsFlag === "S"
          ? parseFloat(vatAmount || 0) +
            parseFloat(vatableSales || 0) +
            parseFloat(vatExemptSales || 0)
          : 0;

      const csvSalesR =
        slsFlag === "R"
          ? parseFloat(vatAmount || 0) +
            parseFloat(vatableSales || 0) +
            parseFloat(vatExemptSales || 0)
          : 0;

      // Calculate net sales (S - R)
      const csvSales = csvSalesS - csvSalesR;

      const hour = csvTime ? csvTime.split(":")[0] : "Unknown";

      if (!groupedTransactions[csvDate]) {
        groupedTransactions[csvDate] = {};
      }

      if (!groupedTransactions[csvDate][hour]) {
        groupedTransactions[csvDate][hour] = {
          csvDate: csvDate || "-",
          csvTime: hour + ":00", // Set the time to the start of the hour
          csvSales: 0,
          csvCount: 0, // Initialize count to 0
          countS: 0, // Initialize S count only
        };
      }
      groupedTransactions[csvDate][hour].csvSales += csvSales; // Use the calculated csvSales

      // Only track S count
      if (slsFlag === "S") {
        groupedTransactions[csvDate][hour].countS += 1;
        groupedTransactions[csvDate][hour].csvCount += 1; // Increment csvCount only for S transactions
      }
      if (slsFlag === "R") {
        groupedTransactions[csvDate][hour].countR += 1; // Increment csvCount only for S transactions
        groupedTransactions[csvDate][hour].csvCount += 1; // Increment csvCount only for S transactions
      }
    });

    // Convert the grouped transactions back to an array
    const updatedCsvTransactions = [];
    for (const date in groupedTransactions) {
      for (const hour in groupedTransactions[date]) {
        updatedCsvTransactions.push(groupedTransactions[date][hour]);
      }
    }

    // Set the state with the updated transactions
    setCsvTransactions(updatedCsvTransactions);

    // Update multiFileResults with the raw CSV data
    setMultiFileResults(csvTransaction);
  };

  const handleGrossSales = (salesData) => {
    const {
      grossSales = 0,
      vatAmount = 0,
      vatableSales = 0,
      nonVatSales = 0,
      vatExemptSales = 0,
      vatExemptAmount = 0,
      discounts = 0,
      snrCitDisc = 0,
      pwdDisc = 0,
      refundAmount = 0,
      voidAmount = 0,
      servchargeAmount = 0,
      cashAmount = 0,
      cardAmount = 0,
      dCardAmount = 0,
      ePayAmount = 0,
      onlineSales = 0,
      checkSales = 0,
      gcSales = 0,
      foodPandaSales = 0,
      grabSales = 0,
    } = salesData;

    setGrossSales(grossSales);
    setVatAmount(vatAmount);
    setVatableSales(vatableSales);
    setNonVatSales(nonVatSales);
    setVatExemptSales(vatExemptSales);
    setVatExemptAmount(vatExemptAmount);
    setDiscount(discounts);
    setSnrCitDisc(snrCitDisc);
    setPwdDisc(pwdDisc);
    setRefundAmount(refundAmount);
    setVoidAmount(voidAmount);
    setServchargeAmount(servchargeAmount);
    setCashAmount(cashAmount);
    setCardAmount(cardAmount);
    setDcardAmount(dCardAmount);
    setEpayAmount(ePayAmount);
    setOnlineSales(onlineSales);
    setGcSales(gcSales);
    setCheckSales(checkSales);
    setFoodPandaSales(foodPandaSales);
    setGrabSales(grabSales);
    setIsFileUploaded(true);
  };

  const computeSales = (flag) => {
    return (field) =>
      multiFileResults
        .filter((res) => res.slsFlag === flag)
        .reduce((sum, res) => sum + parseFloat(res[field] || 0), 0);
  };

  const salesByFlag = useMemo(() => {
    const getSales = computeSales("S");
    const getRefunds = computeSales("R");

    return {
      grossSalesValue: getSales("grossSales") || 0,
      vatAmount: getSales("vatAmount") - getRefunds("vatAmount") || 0,
      vatSales: getSales("vatableSales") - getRefunds("vatableSales") || 0,
      nonVatSales: getSales("nonVatSales") - getRefunds("nonVatSales") || 0,
      vatExemptSales:
        Math.abs(getSales("vatExemptSales")) -
          Math.abs(getRefunds("vatExemptSales")) || 0,
      vatExemptAmount: getSales("vatExemptAmount") || 0,
      nonVatSales: getSales("nonvatSales") || 0,
      discountAmount:
        (getSales("discountAmount") || getRefunds("discountAmount") || 0) +
        (Math.abs(getSales("snrCitDisc")) -
          Math.abs(getRefunds("snrCitDisc")) || 0) +
        (Math.abs(getSales("pwdDisc")) - Math.abs(getRefunds("pwdDisc")) || 0),
      snrCitDisc:
        Math.abs(getSales("snrCitDisc")) - Math.abs(getRefunds("snrCitDisc")) ||
        0,
      pwdDisc:
        Math.abs(getSales("pwdDisc")) - Math.abs(getRefunds("pwdDisc")) || 0,
      refundAmount:
        getRefunds("grossSales") - getRefunds("discountAmount") || 0,
      voidAmount: getSales("voidAmount") || 0,
      servchargeAmount:
        getSales("servchargeAmount") || getRefunds("servchargeAmount") || 0,
      cashAmount:
        Math.abs(getSales("cashAmount")) - Math.abs(getRefunds("cashAmount")) ||
        0,
      cardAmount:
        getSales("cardAmount") - Math.abs(getRefunds("cardAmount")) || 0,
      dCardAmount:
        getSales("dCardAmount") - Math.abs(getRefunds("dCardAmount")) || 0,
      ePayAmount:
        getSales("ePayAmount") - Math.abs(getRefunds("ePayAmount")) || 0,
      onlineSales:
        getSales("onlineSales") - Math.abs(getRefunds("onlineSales")) || 0,
      checkSales:
        getSales("checkAmount") - Math.abs(getRefunds("checkAmount")) || 0,
      gcSales: getSales("gcAmount") - Math.abs(getRefunds("gcAmount")) || 0,
      foodPandaSales:
        getSales("foodPandaSales") - Math.abs(getRefunds("foodPandaSales")) ||
        0,
      grabSales: getSales("grabSales") - Math.abs(getRefunds("grabSales")) || 0,
    };
  }, [multiFileResults]);

  const isMatching = (calculatedValue, csvValue) =>
    Math.abs(calculatedValue - Number(csvValue)) < 0.01;

  const priorityDescriptions = [
    "GROSS_SLS",
    "VAT_AMNT",
    "VATABLE_SLS",
    "NONVAT_SLS",
  ];

  const formatNumber = (value) => {
    if (value || value === 0) {
      return value.toLocaleString();
    }
    return "-";
  };

  const calculateTotal = (data, field) => {
    if (!Array.isArray(data)) return 0; // Ensure data is an array
    return data.reduce((total, item) => {
      const value = item[field] || 0; // Default to 0 if field is undefined
      return total + (typeof value === "number" ? value : 0);
    }, 0);
  };

  const calculateTotals = (results) => {
    return results.reduce(
      (totals, result) => {
        const slsFlag = result.slsFlag;
        const grossSales = parseFloat(result.grossSales) || 0;
        const cashAmount = parseFloat(result.cashAmount) || 0;
        const vatAmount = parseFloat(result.vatAmount) || 0;
        const vatableSales = parseFloat(result.vatableSales) || 0;
        const nonvatSales = parseFloat(result.nonvatSales) || 0;

        if (slsFlag === "R") {
          totals.grossSales += 0;
          totals.cashAmount += 0;
          totals.nonvatSales += 0;
          totals.vatAmount += 0;
          totals.vatableSales += 0;
        } else {
          totals.grossSales += grossSales;
          totals.cashAmount += cashAmount;
          totals.nonvatSales += nonvatSales;
          totals.vatAmount += vatAmount;
          totals.vatableSales += vatableSales;
        }

        totals.discountAmount += parseFloat(result.discountAmount) || 0;
        totals.cardAmount += parseFloat(result.cardAmount) || 0;
        totals.ePayAmount += parseFloat(result.ePayAmount) || 0;
        totals.dCardAmount += parseFloat(result.dCardAmount) || 0;
        totals.gcAmount += parseFloat(result.gcAmount) || 0;
        totals.onlineSales += parseFloat(result.onlineSales) || 0;
        totals.vatExemptSales += parseFloat(result.vatExemptSales) || 0;
        totals.vatExemptAmount += parseFloat(result.vatExemptAmount) || 0;
        totals.refundAmount += parseFloat(result.refundAmount) || 0;
        totals.voidAmount += parseFloat(result.voidAmount) || 0;
        totals.snrCitDisc += parseFloat(result.snrCitDisc) || 0;
        totals.pwdDisc += parseFloat(result.pwdDisc) || 0;
        totals.servchargeAmount += parseFloat(result.servchargeAmount) || 0;
        totals.checkSales += parseFloat(result.checkSales) || 0;
        totals.grabSales += parseFloat(result.grabSales) || 0;
        totals.foodPandaSales += parseFloat(result.foodPandaSales) || 0;

        return totals;
      },
      {
        grossSales: 0,
        vatAmount: 0,
        vatableSales: 0,
        vatExemptSales: 0,
        vatExemptAmount: 0,
        nonvatSales: 0,
        snrCitDisc: 0,
        pwdDisc: 0,
        discountAmount: 0,
        cashAmount: 0,
        cardAmount: 0,
        ePayAmount: 0,
        dCardAmount: 0,
        gcAmount: 0,
        onlineSales: 0,
        refundAmount: 0,
        voidAmount: 0,
        servchargeAmount: 0,
        checkSales: 0,
        foodPandaSales: 0,
        grabSales: 0,
      }
    );
  };

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h1 style={{ textAlign: "center" }}> AYALA TEXT FILE CHECKER</h1>
      <FileUploader
        handleHeaderResults={handleHeaderResults}
        handleDetailsResults={handleDetailsResults}
      />
      <MultiFileUploader
        handleMultiFileResults={handleMultiFileResults}
        calculateTotals={calculateTotals} // Pass the function as a prop
      />
      <EODFileUploader handleGrossSales={handleGrossSales} />

      {isFileUploaded && (
        <div className="mt-5">
          <hr />
          <h3>Cross Checking Results</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Description</th>
                <th>Raw Gross (Header)</th>
                <th>Hourly Files Gross Sales</th>
                <th>Gross Sales (EOD)</th>
                <th>Discrepancy</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {[
                [
                  "GROSS_SLS",
                  rawGrossValue,
                  salesByFlag.grossSalesValue,
                  grossSales,
                ],
                ["VAT_AMNT", rawVatValue, salesByFlag.vatAmount, vatAmount],
                [
                  "VATABLE_SLS",
                  rawVatableSalesValue - headerNonVat,
                  salesByFlag.vatSales,
                  vatableSales,
                ],
                [
                  "NONVAT_SLS",
                  headerNonVat,
                  salesByFlag.nonVatSales,
                  nonVatSales,
                ],
                [
                  "VATEXEMPT_SLS",
                  "",
                  salesByFlag.vatExemptSales,
                  vatExemptSales,
                ],
                [
                  "VATEXEMPT_AMNT",
                  "",
                  salesByFlag.vatExemptAmount,
                  vatExemptAmount,
                ],
                [
                  "DISCOUNTS",
                  headerTotDisc,
                  salesByFlag.discountAmount,
                  discounts,
                ],
                ["SNRCIT_DISC", "", salesByFlag.snrCitDisc, snrCitDisc],
                ["PWD_DISC", "", salesByFlag.pwdDisc, pwdDisc],
                [
                  "REFUND_AMT",
                  headerTotRef,
                  salesByFlag.refundAmount,
                  refundAmount,
                ],
                ["VOID_AMNT", "", salesByFlag.voidAmount, voidAmount],
                [
                  "SCHRGE_AMT",
                  headerServCharge,
                  salesByFlag.servchargeAmount,
                  servchargeAmount,
                ],
                ["CASH_SLS", "", salesByFlag.cashAmount, cashAmount],
                ["CARD_SLS", "", salesByFlag.cardAmount, cardAmount],
                ["DCARD_SLS", "", salesByFlag.dCardAmount, dCardAmount],
                ["EPAY_SLS", "", salesByFlag.ePayAmount, ePayAmount],
                ["ONLINE_SLS", "", salesByFlag.onlineSales, onlineSales],
                ["CHECK_SLS", "", salesByFlag.checkSales, checkSales],
                ["GC_SLS", "", salesByFlag.gcSales, gcSales],
                [
                  "FOODPANDA_SLS",
                  "",
                  salesByFlag.foodPandaSales,
                  foodPandaSales,
                ],
                ["GRAB_SLS", "", salesByFlag.grabSales, grabSales],
              ].map(([description, raw, hourly, eod], index) => (
                <tr key={index}>
                  <td>{description}</td>
                  <td>{formatNumber(raw)}</td>
                  <td>{formatNumber(hourly)}</td>
                  <td>{formatNumber(eod)}</td>
                  <td>{raw && eod ? (raw - eod).toFixed(2) : "-"}</td>
                  <td>
                    {priorityDescriptions.includes(description) ? (
                      description === "VATABLE_SLS" ? (
                        isMatching(hourly, eod) &&
                        isMatching(hourly, raw - headerNonVat) ? (
                          <span className="badge bg-success">Match</span>
                        ) : (
                          <span className="badge bg-danger">Mismatch</span>
                        )
                      ) : isMatching(hourly, eod) && isMatching(hourly, raw) ? (
                        <span className="badge bg-success">Match</span>
                      ) : (
                        <span className="badge bg-danger">Mismatch</span>
                      )
                    ) : isMatching(hourly, eod) || isMatching(hourly, raw) ? (
                      <span className="badge bg-success">Match</span>
                    ) : (
                      <span className="badge bg-danger">Mismatch</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div>
        <h3>TXTHourlyChecker</h3>
        {(() => {
          const combinedData = [];

          // Add transactions to combinedData
          if (Array.isArray(transactions) && transactions.length > 0) {
            transactions.forEach((transaction) => {
              const {
                transDate = "-",
                hour = "-",
                sales = 0,
                transCount = 0,
              } = transaction;

              // Find corresponding CSV transaction
              const csvTransaction =
                csvTransactions.find(
                  (csv) => csv.csvTime === hour // Match based on hour
                ) || {};

              // Only add to combinedData if transDate and hour are not empty or whitespace
              if (transDate.trim() !== "-" && hour.trim() !== "-") {
                combinedData.push({
                  transDate: transDate.trim(), // Trim whitespace from transDate
                  hour: hour.trim(), // Trim whitespace from hour
                  sales,
                  transCount,
                  csvDate: (csvTransaction.csvDate || "-").trim(), // Trim whitespace from csvDate
                  csvTime: (csvTransaction.csvTime || "-").trim(), // Trim whitespace from csvTime
                  csvSales: csvTransaction.csvSales || 0,
                  csvCount: csvTransaction.csvCount || 0,
                });
              }
            });
          }

          // If there are no transactions, check for CSV transactions
          if (
            Array.isArray(csvTransactions) &&
            csvTransactions.length > 0 &&
            combinedData.length === 0
          ) {
            csvTransactions.forEach((csvTransaction) => {
              const {
                csvDate = "-",
                csvTime = "-",
                csvSales = 0,
                csvCount = 0,
              } = csvTransaction;

              // Only add to combinedData if csvDate and csvTime are not empty or whitespace
              if (csvDate.trim() !== "-" && csvTime.trim() !== "-") {
                combinedData.push({
                  transDate: "-",
                  hour: "-",
                  sales: 0,
                  transCount: 0,
                  csvDate: csvDate.trim(), // Trim whitespace from csvDate
                  csvTime: csvTime.trim(), // Trim whitespace from csvTime
                  csvSales,
                  csvCount,
                });
              }
            });
          }

          // Check if combinedData is empty
          if (combinedData.length === 0) {
            return (
              <div className="text-center">
                <p>No Transactions Available</p>
              </div>
            );
          }

          // Render combined data
          return (
            <Table striped bordered hover>
              <thead className="thead-light">
                <tr>
                  <th>TXT_DATE</th>
                  <th>TXT_HOUR</th>
                  <th>TXT_SALES</th>
                  <th>TXT_COUNT</th>
                  <th>CSV_DATE</th>
                  <th>CSV_HOUR</th>
                  <th>CSV_SALES</th>
                  <th>CSV_COUNT</th>
                  <th>VARIANCE</th>
                </tr>
              </thead>
              <tbody>
                {combinedData.map((data, index) => {
                  const {
                    transDate,
                    hour,
                    sales,
                    transCount,
                    csvDate,
                    csvTime,
                    csvSales,
                    csvCount,
                  } = data;

                  // Determine the class based on variance
                  let varianceDisplay;
                  let varianceClass = "";
                  // Calculate variance
                  const variance = sales - csvSales;

                  if (Math.abs(variance) < 0.01) {
                    // Check if variance is within the tolerance range
                    varianceDisplay = "Passed";
                    varianceClass = "text-success"; // Bootstrap success class
                  } else if (variance < 0) {
                    varianceDisplay = variance.toFixed(2);
                    varianceClass = "text-danger"; // Bootstrap danger class for negative variance
                  } else {
                    varianceDisplay = variance.toFixed(2);
                    varianceClass = ""; // Default class for positive variance
                  }

                  return (
                    <tr key={index}>
                      <td>{transDate}</td>
                      <td>{hour}</td>
                      <td>{sales.toFixed(2)}</td>
                      <td>{transCount}</td>
                      <td>{csvDate}</td>
                      <td>{csvTime}</td>
                      <td>{csvSales.toFixed(2)}</td>
                      <td>{csvCount}</td>
                      <td className={varianceClass}>
                        <strong>({varianceDisplay})</strong>
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan={2}>
                    <strong>Total</strong>
                  </td>
                  <td>{calculateTotal(transactions, "sales").toFixed(2)}</td>
                  <td>{calculateTotal(transactions, "transCount")}</td>
                  <td>{""}</td>
                  <td>{""}</td>
                  <td>
                    {calculateTotal(csvTransactions, "csvSales").toFixed(2)}
                  </td>
                  <td>{calculateTotal(csvTransactions, "csvCount")}</td>
                  <td>
                    {(
                      calculateTotal(transactions, "sales") -
                      calculateTotal(csvTransactions, "csvSales")
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          );
        })()}
      </div>
    </div>
  );
};

export default MainFileUploader;
