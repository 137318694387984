import React, { useState } from "react";
import Papa from "papaparse";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./file.css";

const MultiFileUploader = ({ handleMultiFileResults }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // In handleFileUpload, ensure to call handleMultiFileResults with allResults
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    let allResults = [];
    setLoading(true);
    setError(null);

    files.forEach((file) => {
      if (file.type !== "text/csv") {
        setError("Only CSV files are allowed.");
        setLoading(false);
        return;
      }

      Papa.parse(file, {
        header: false,
        complete: (result) => {
          const data = result.data; // Parse CSV data
          const processedResults = processFileData(data, file.name);
          allResults = [...allResults, ...processedResults]; // Accumulate results

          // Update results after all files are processed
          if (allResults.length) {
            setResults(allResults);
            handleMultiFileResults(allResults); // Pass the processed results to the parent
          }
          setLoading(false);
        },
        error: (err) => {
          setError("Error parsing file: " + err.message);
          setLoading(false);
        },
      });
    });
  };

  // Helper function to format date consistently
  const formatErrorDate = (date) => {
    return `[${date.split("/").join("-")}]`;
  };

  const processFileData = (data, fileName) => {
    const processedResults = [];
    const transactions = {};
    let currentTransactionNo = null;
    let currentCsvDate = null;
    let currentCsvTime = null;
    let currentCsvCount = null;
    let firstTransactionNo = null;
    let lastTransactionNo = null;
    let noTrn = null;
    let hasProcessedCount = false;

    // Group transactions by date to track first and last transaction numbers
    const dateTransactions = {};

    // First pass: find first and last transaction numbers and NO_TRN
    data.forEach((row) => {
      const index0 = row[0]?.trim();
      const index1 = row[1]?.trim();

      if (index0 === "TRANSACTION_NO") {
        const transNo = parseInt(index1);
        if (!firstTransactionNo || transNo < firstTransactionNo) {
          firstTransactionNo = transNo;
        }
        if (!lastTransactionNo || transNo > lastTransactionNo) {
          lastTransactionNo = transNo;
        }
      } else if (index0 === "NO_TRN") {
        noTrn = parseInt(index1);
      }
    });

    // Validate transaction count
    if (firstTransactionNo && lastTransactionNo && noTrn) {
      const calculatedCount = lastTransactionNo - firstTransactionNo + 1;
      console.log("File:", fileName);
      console.log("First Transaction:", firstTransactionNo);
      console.log("Last Transaction:", lastTransactionNo);
      console.log("Calculated Count:", calculatedCount);
      console.log("NO_TRN:", noTrn);

      if (calculatedCount !== noTrn) {
        const errorMessage = `Transaction Count Mismatch in ${fileName}\nExpected (NO_TRN): ${noTrn}\nFound: ${calculatedCount} (${firstTransactionNo}-${lastTransactionNo})`;
        setError(errorMessage);
      }
    }

    // Second pass: process the data
    data.forEach((row) => {
      const index0 = row[0]?.trim();
      const index1 = row[1]?.trim();

      if (index0 === "TRANSACTION_NO") {
        currentTransactionNo = index1;
        if (!transactions[currentTransactionNo]) {
          transactions[currentTransactionNo] = {
            fileName: fileName,
            transactionNo: currentTransactionNo,
            slsFlag: null,
            csvCount: currentCsvCount,
            csvTime: currentCsvTime,
            csvDate: currentCsvDate,
            // Sales values
            grossSales: 0,
            vatAmount: 0,
            vatableSales: 0,
            nonvatSales: 0,
            vatExemptSales: 0,
            vatExemptAmount: 0,
            localTax: 0,
            // Discounts
            pwdDisc: 0,
            snrCitDisc: 0,
            emploDisc: 0,
            ayalaDisc: 0,
            storeDisc: 0,
            otherDisc: 0,
            discountAmount: 0,
            // Other amounts
            refundAmount: 0,
            voidAmount: 0,
            servchargeAmount: 0,
            // Payment methods
            cashAmount: 0,
            cardSLS: 0,
            dCardSLS: 0,
            ePaySLS: 0,
            cardAmount: 0,
            ePayAmount: 0,
            dCardAmount: 0,
            gcAmount: 0,
            onlineSales: 0,
            openSales: 0,
            gcExcess: 0,
            checkSales: 0,
            foodPandaSales: 0,
            grabSales: 0,
          };
        }
      } else if (index0 === "CDATE") {
        currentCsvDate = index1;
      } else if (index0 === "TRN_TIME") {
        currentCsvTime = index1;
      } else if (index0 === "NO_TRN" && !hasProcessedCount) {
        hasProcessedCount = true;
        currentCsvCount = parseInt(index1);
      } else if (index0 && index1 && currentTransactionNo) {
        const value = parseFloat(index1) || 0;
        const trans = transactions[currentTransactionNo];

        switch (index0) {
          case "SLS_FLAG":
            trans.slsFlag = index1;
            break;
          case "GROSS_SLS":
            trans.grossSales += value;
            break;
          case "VAT_AMNT":
            trans.vatAmount += value;
            break;
          case "VATABLE_SLS":
            trans.vatableSales += value;
            break;
          case "NONVAT_SLS":
            trans.nonvatSales += value;
            break;
          case "VATEXEMPT_SLS":
            trans.vatExemptSales += value;
            break;
          case "VATEXEMPT_AMNT":
            trans.vatExemptAmount += value;
            break;
          case "LOCAL_TAX":
            trans.localTax += value;
            break;
          case "PWD_DISC":
            trans.pwdDisc += value;
            trans.discountAmount += value;
            break;
          case "SNRCIT_DISC":
            trans.snrCitDisc += value;
            trans.discountAmount += value;
            break;
          case "EMPLO_DISC":
            trans.emploDisc += value;
            trans.discountAmount += value;
            break;
          case "AYALA_DISC":
            trans.ayalaDisc += value;
            trans.discountAmount += value;
            break;
          case "STORE_DISC":
            trans.storeDisc += value;
            trans.discountAmount += value;
            break;
          case "OTHER_DISC":
            trans.otherDisc += value;
            trans.discountAmount += value;
            break;
          case "REFUND_AMT":
            trans.refundAmount += value;
            break;
          case "SCHRGE_AMT":
            trans.servchargeAmount += value;
            break;
          case "CASH_SLS":
            trans.cashAmount += value;
            break;
          case "CHECK_SLS":
            trans.checkSales += value;
            break;
          case "FOODPANDA_SLS":
            trans.foodPandaSales += value;
            break;
          case "GRAB_SLS":
            trans.grabSales += value;
            break;
          case "GC_SLS":
            trans.gcAmount += value;
            break;
          case "CARD_SLS":
            trans.cardSLS = value;
            break;
          case "MASTERCARD_SLS":
          case "VISA_SLS":
          case "AMEX_SLS":
          case "DINERS_SLS":
          case "JCB_SLS":
            if (!trans.cardAmount) {
              trans.cardAmount = 0;
            }
            trans.cardAmount += value;

            // Card payment validation
            if (
              index0 === "JCB_SLS" &&
              Math.abs(trans.cardSLS - trans.cardAmount) > 0.01
            ) {
              const errorDate = formatErrorDate(currentCsvDate);
              const cardError = `${errorDate} Card Payment Discrepancy in ${fileName} - Transaction #${currentTransactionNo}`;
              setError((prev) => {
                if (!prev) return cardError;
                return prev.split("\n").concat(cardError).sort().join("\n");
              });
            }
            break;
          case "EPAY_SLS":
            trans.ePaySLS = value;
            break;
          case "GCASH_SLS":
          case "PAYMAYA_SLS":
          case "ALIPAY_SLS":
          case "WECHAT_SLS":
            if (!trans.ePayAmount) {
              trans.ePayAmount = 0;
            }
            trans.ePayAmount += value;

            // E-payment validation
            if (
              index0 === "WECHAT_SLS" &&
              Math.abs(trans.ePaySLS - trans.ePayAmount) > 0.01
            ) {
              const errorDate = formatErrorDate(currentCsvDate);
              const ePayError = `${errorDate} E-Payment Discrepancy in ${fileName} - Transaction #${currentTransactionNo}`;
              setError((prev) => {
                if (!prev) return ePayError;
                return prev.split("\n").concat(ePayError).sort().join("\n");
              });
            }
            break;
          case "DCARD_SLS":
            trans.dCardSLS = value;
            break;
          case "MASTERDEBIT_SLS":
          case "VISADEBIT_SLS":
            if (!trans.dCardAmount) {
              trans.dCardAmount = 0;
            }
            trans.dCardAmount += value;

            // Debit card validation
            if (
              index0 === "VISADEBIT_SLS" &&
              Math.abs(trans.dCardSLS - trans.dCardAmount) > 0.01
            ) {
              const errorDate = formatErrorDate(currentCsvDate);
              const dCardError = `${errorDate} Debit Card Payment Discrepancy in ${fileName} - Transaction #${currentTransactionNo}`;
              setError((prev) => {
                if (!prev) return dCardError;
                return prev.split("\n").concat(dCardError).sort().join("\n");
              });
            }
            break;
          case "ONLINE_SLS":
            trans.onlineSales += value;
            break;
          case "OPEN_SALES":
          case "OPEN_SALES_2":
          case "OPEN_SALES_3":
            trans.openSales += value;
            break;
          case "GC_EXCESS":
            trans.gcExcess += value;
            break;
          default:
            break;
        }
      }
    });

    // Prepare the processed results
    Object.values(transactions).forEach((transaction) => {
      processedResults.push({
        fileName,
        transactionNo: transaction.transactionNo,
        slsFlag: transaction.slsFlag,
        csvDate: transaction.csvDate,
        csvTime: transaction.csvTime,
        csvCount: transaction.csvCount,
        grossSales: transaction.grossSales.toFixed(2),
        vatAmount: transaction.vatAmount.toFixed(2),
        vatableSales: transaction.vatableSales.toFixed(2),
        vatExemptSales: transaction.vatExemptSales.toFixed(2),
        vatExemptAmount: transaction.vatExemptAmount.toFixed(2),
        nonvatSales: transaction.nonvatSales.toFixed(2),
        snrCitDisc: transaction.snrCitDisc.toFixed(2),
        pwdDisc: transaction.pwdDisc.toFixed(2),
        servchargeAmount: transaction.servchargeAmount.toFixed(2),
        discountAmount: transaction.discountAmount.toFixed(2),
        cashAmount: transaction.cashAmount.toFixed(2),
        cardAmount: transaction.cardAmount.toFixed(2),
        ePayAmount: transaction.ePayAmount.toFixed(2),
        dCardAmount: transaction.dCardAmount.toFixed(2),
        gcAmount: transaction.gcAmount.toFixed(2),
        onlineSales: (
          transaction.onlineSales +
          transaction.openSales +
          transaction.gcExcess
        ).toFixed(2),
        refundAmount: transaction.refundAmount.toFixed(2),
        foodPandaSales: transaction.foodPandaSales.toFixed(2),
        grabSales: transaction.grabSales.toFixed(2),
        checkSales: transaction.checkSales.toFixed(2),
      });
    });

    return processedResults;
  };

  const calculateTotals = () => {
    return results.reduce(
      (totals, res) => {
        const grossSalesValue = parseFloat(res.grossSales) || 0;
        const cashAmountValue = parseFloat(res.cashAmount) || 0;
        const vatAmountValue = parseFloat(res.vatAmount) || 0;
        const vatableSalesValue = parseFloat(res.vatableSales) || 0;
        const nonvatSalesValue = parseFloat(res.nonvatSales) || 0;
        const slsFlag = res.slsFlag;

        // For 'R' flag, treat gross sales as zero (refund)
        if (slsFlag === "R") {
          totals.grossSales += 0; // Ignore this gross sale, essentially making it zero
          totals.cashAmount += 0; // Ignore this gross sale, essentially making it zero
          totals.nonvatSales += 0; // Ignore this gross sale, essentially making it zero
          totals.vatAmount += 0; // Ignore this gross sale, essentially making it zero
          totals.vatableSales += 0; // Ignore this gross sale, essentially making it zero
        } else {
          totals.grossSales += grossSalesValue; // Add normally for other SLS_FLAGs
          totals.cashAmount += cashAmountValue; // Add normally for other SLS_FLAGs
          totals.nonvatSales += nonvatSalesValue; // Add normally for other SLS_FLAGs
          totals.vatAmount += vatAmountValue; // Add normally for other SLS_FLAGs
          totals.vatableSales += vatableSalesValue; // Add normally for other SLS_FLAGs
        }

        // Add other fields normally
        totals.discountAmount += parseFloat(res.discountAmount) || 0;
        totals.cardAmount += parseFloat(res.cardAmount) || 0;
        totals.ePayAmount += parseFloat(res.ePayAmount) || 0;
        totals.dCardAmount += parseFloat(res.dCardAmount) || 0;
        totals.gcAmount += parseFloat(res.gcAmount) || 0;
        totals.onlineSales += parseFloat(res.onlineSales) || 0;
        totals.vatExemptSales += parseFloat(res.vatExemptSales) || 0;
        totals.vatExemptAmount += parseFloat(res.vatExemptAmount) || 0;
        totals.nonvatSales += parseFloat(res.nonvatSales) || 0;
        totals.refundAmount += parseFloat(res.refundAmount) || 0; // Add refund amount
        totals.snrCitDisc += parseFloat(res.snrCitDisc) || 0; // Add refund amount
        totals.pwdDisc += parseFloat(res.pwdDisc) || 0; // Add refund amount
        totals.servchargeAmount += parseFloat(res.servchargeAmount) || 0; // Add refund amount
        totals.checkSales += parseFloat(res.checkSales) || 0; // Add refund amount
        totals.grabSales += parseFloat(res.grabSales) || 0; // Add refund amount
        totals.foodPandaSales += parseFloat(res.foodPandaSales) || 0; // Add refund amount

        return totals;
      },
      {
        grossSales: 0,
        vatAmount: 0,
        vatableSales: 0,
        vatExemptSales: 0,
        vatExemptAmount: 0,
        nonvatSales: 0,
        snrCitDisc: 0,
        pwdDisc: 0,
        discountAmount: 0,
        cashAmount: 0,
        cardAmount: 0,
        ePayAmount: 0,
        dCardAmount: 0,
        gcAmount: 0,
        onlineSales: 0,
        refundAmount: 0, // Initialize refund amount
        servchargeAmount: 0, // Initialize refund amount
        checkSales: 0, // Initialize refund amount
        foodPandaSales: 0, // Initialize refund amount
        grabSales: 0, // Initialize refund amount
      }
    );
  };
  const totals = calculateTotals();

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white">
          <h3 className="mb-0" style={{ fontSize: "1.5rem" }}>
            <i className="fas fa-file-csv me-2"></i>
            Hourly CSV Upload
          </h3>
        </div>
        <div className="card-body">
          <div className="mb-4">
            <label className="form-label fw-bold">Select CSV Files</label>
            <div className="input-group">
              <input
                type="file"
                accept=".csv"
                className="form-control"
                multiple
                onChange={handleFileUpload}
                style={{ cursor: "pointer" }}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() =>
                  (document.querySelector('input[type="file"]').value = "")
                }
                title="Clear selected files"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <small className="text-muted">
              Multiple CSV files can be selected at once
            </small>
          </div>

          {loading && (
            <div className="d-flex align-items-center text-primary mb-3">
              <div
                className="spinner-border spinner-border-sm me-2"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              Processing files...
            </div>
          )}

          {error && (
            <div
              className="alert alert-danger"
              style={{
                whiteSpace: "pre-line",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <div className="d-flex align-items-center mb-2">
                <i className="fas fa-exclamation-triangle me-2"></i>
                <strong>Transaction Count Mismatches Detected:</strong>
              </div>
              {error}
            </div>
          )}

          {results.length > 0 && (
            <div className="table-responsive" style={{ maxHeight: "600px" }}>
              <Table
                striped
                bordered
                hover
                className="table-sm"
                style={{ fontSize: "0.9rem" }}
              >
                <thead className="sticky-top bg-white">
                  <tr>
                    <th className="text-nowrap">File Name</th>
                    <th className="text-nowrap">Trans #</th>
                    <th className="text-nowrap">Date</th>
                    <th className="text-nowrap">Time</th>
                    {/* SLS_FLAG = S */}
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      Gross Sales (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      VAT Amount (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      VAT Sales (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT SLS (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT AMNT (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      NON VAT (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      SC AMOUNT(S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      PWD AMOUNT(S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      OTHER DISC (S)
                    </th>
                    <th
                      style={{ backgroundColor: "#8DB4E2" }}
                      className="text-nowrap"
                    >
                      REFUND AMOUNT (S)
                    </th>
                    {/* END OF SLS FLAG = S */}
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      Gross Sales (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      VAT Amount (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      VAT Sales (R)
                    </th>

                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT SLS (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT AMNT (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      NON VAT (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      SC AMOUNT (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      PWD AMOUNT (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      OTHER DISC (R)
                    </th>
                    <th
                      style={{ backgroundColor: "#FABF8F" }}
                      className="text-nowrap"
                    >
                      REFUND AMOUNT (R)
                    </th>
                    {/* END OF R*/}
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      GROSS SALES (NET)
                    </th>

                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      Vatable Sales(NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      VAT Amount(NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT SLS (NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      VAT EXEMPT AMNT (NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      NON VAT (NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      SC AMOUNT (NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      PWD AMOUNT (NET)
                    </th>
                    <th
                      style={{ backgroundColor: "#92D050" }}
                      className="text-nowrap"
                    >
                      OTHER DISC (NET)
                    </th>
                    {/* END OF NET */}
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      Cash Amount
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      Card Amount
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      Debit Card Amount
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      E-Pay Amount
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      GC
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      Online Sales
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      Check Sales
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      FoodPanda
                    </th>
                    <th
                      style={{ backgroundColor: "#B1A0C7" }}
                      className="text-nowrap"
                    >
                      GrabFood
                    </th>
                    <th
                      style={{ backgroundColor: "#ff2" }}
                      className="text-nowrap"
                    >
                      Tender
                    </th>
                    <th
                      style={{ backgroundColor: "#ff2" }}
                      className="text-nowrap"
                    >
                      NET
                    </th>
                    <th
                      style={{ backgroundColor: "#ff2" }}
                      className="text-nowrap"
                    >
                      Discrepancy{" "}
                    </th>
                    <th
                      style={{ backgroundColor: "#ff2" }}
                      className="text-nowrap"
                    >
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td>{result.fileName.split("_").pop()}</td>
                      <td>{result.transactionNo}</td>
                      <td style={{ whiteSpace: "nowrap" }}>{result.csvDate}</td>
                      <td>{result.csvTime}</td>
                      {/* <td>{result.csvCount}</td> */}
                      {/* S */}
                      <td>{result.slsFlag === "S" ? result.grossSales : ""}</td>
                      <td>{result.slsFlag === "S" ? result.vatAmount : ""}</td>
                      <td>
                        {result.slsFlag === "S" ? result.vatableSales : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S" ? result.vatExemptSales : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S" ? result.vatExemptAmount : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S" ? result.nonvatSales : ""}
                      </td>
                      <td>{result.slsFlag === "S" ? result.snrCitDisc : ""}</td>
                      <td>{result.slsFlag === "S" ? result.pwdDisc : ""}</td>
                      <td>
                        {result.slsFlag === "S" ? result.discountAmount : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S" ? result.refundAmount : ""}
                      </td>
                      {/* R */}
                      <td>
                        {result.slsFlag === "R" ? result.grossSales * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.vatAmount * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.vatableSales * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R"
                          ? result.vatExemptSales * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R"
                          ? result.vatExemptAmount * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.nonvatSales * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.snrCitDisc * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.pwdDisc * -1 : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R"
                          ? result.discountAmount * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "R" ? result.refundAmount * -1 : ""}
                      </td>
                      {/* NET */}
                      <td>
                        {result.slsFlag === "S"
                          ? result.grossSales
                          : result.slsFlag === "R"
                          ? result.grossSales * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.vatableSales
                          : result.slsFlag === "R"
                          ? result.vatableSales * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.vatAmount
                          : result.slsFlag === "R"
                          ? result.vatAmount * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.vatExemptSales
                          : result.slsFlag === "R"
                          ? result.vatExemptSales * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.vatExemptAmount
                          : result.slsFlag === "R"
                          ? result.vatExemptAmount * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.nonvatSales
                          : result.slsFlag === "R"
                          ? result.nonvatSales * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.snrCitDisc
                          : result.slsFlag === "R"
                          ? result.snrCitDisc * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.pwdDisc
                          : result.slsFlag === "R"
                          ? result.pwdDisc * -1
                          : ""}
                      </td>
                      <td>
                        {result.slsFlag === "S"
                          ? result.discountAmount
                          : result.slsFlag === "R"
                          ? result.discountAmount * -1
                          : ""}
                      </td>
                      {/* Sales Breakdown */}
                      <td>{result.cashAmount}</td>
                      <td>{result.cardAmount}</td>
                      <td>{result.dCardAmount}</td>
                      <td>{result.ePayAmount}</td>
                      <td>{result.gcAmount}</td>
                      <td>{result.onlineSales}</td>
                      <td>{result.checkSales}</td>
                      <td>{result.foodPandaSales}</td>
                      <td>{result.grabSales}</td>
                      {/* Tender */}
                      <td>
                        {Math.abs(
                          [
                            result.cashAmount,
                            result.cardAmount,
                            result.dCardAmount,
                            result.ePayAmount,
                            result.gcAmount,
                            result.onlineSales,
                            result.checkSales,
                            result.foodPandaSales,
                            result.grabSales,
                          ]
                            .filter(
                              (value) => value !== null && value !== undefined
                            )
                            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                        ).toFixed(2)}
                      </td>
                      {/* End Tender */}

                      {/* NET */}
                      <td>
                        {Math.abs(
                          (result.slsFlag === "S" || result.slsFlag === "R"
                            ? result.grossSales
                            : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.discountAmount
                              : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.snrCitDisc
                              : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.pwdDisc
                              : 0)
                        ).toFixed(2)}
                      </td>
                      {/* End Net */}

                      {/* Discrepancy */}
                      <td>
                        {Math.abs(
                          Math.abs(
                            [
                              result.cashAmount,
                              result.cardAmount,
                              result.dCardAmount,
                              result.ePayAmount,
                              result.gcAmount,
                              result.onlineSales,
                              result.checkSales,
                              result.foodPandaSales,
                              result.grabSales,
                            ]
                              .filter(
                                (value) => value !== null && value !== undefined
                              )
                              .reduce(
                                (a, b) => parseFloat(a) + parseFloat(b),
                                0
                              )
                          ) -
                            Math.abs(
                              parseFloat(
                                (result.slsFlag === "S" ||
                                result.slsFlag === "R"
                                  ? result.grossSales
                                  : 0) -
                                  (result.slsFlag === "S" ||
                                  result.slsFlag === "R"
                                    ? result.discountAmount
                                    : 0) -
                                  (result.slsFlag === "S" ||
                                  result.slsFlag === "R"
                                    ? result.snrCitDisc
                                    : 0) -
                                  (result.slsFlag === "S" ||
                                  result.slsFlag === "R"
                                    ? result.pwdDisc
                                    : 0)
                              )
                            )
                        ).toFixed(2)}
                      </td>
                      {/* End Discrepancy */}

                      {/* Match / Mismatch */}
                      <td
                        style={{
                          color: "#ffffff",
                          marginTop: "5px",
                          marginLeft: "5px",
                        }}
                        className={
                          Math.abs(
                            parseFloat(
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.grossSales
                                : 0) -
                                (result.slsFlag === "S" ||
                                result.slsFlag === "R"
                                  ? result.discountAmount
                                  : 0) -
                                (result.slsFlag === "S" ||
                                result.slsFlag === "R"
                                  ? result.snrCitDisc
                                  : 0) -
                                (result.slsFlag === "S" ||
                                result.slsFlag === "R"
                                  ? result.pwdDisc
                                  : 0)
                            ).toFixed(2)
                          ) ===
                          Math.abs(
                            parseFloat(
                              [
                                result.cashAmount,
                                result.cardAmount,
                                result.dCardAmount,
                                result.ePayAmount,
                                result.gcAmount,
                                result.onlineSales,
                                result.checkSales,
                                result.foodPandaSales,
                                result.grabSales,
                              ]
                                .filter(
                                  (value) =>
                                    value !== null && value !== undefined
                                )
                                .reduce(
                                  (a, b) => parseFloat(a) + parseFloat(b),
                                  0
                                )
                            ).toFixed(2)
                          )
                            ? "badge bg-success"
                            : "badge bg-danger"
                        }
                      >
                        {Math.abs(
                          parseFloat(
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.grossSales
                              : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.discountAmount
                                : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.snrCitDisc
                                : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.pwdDisc
                                : 0)
                          ).toFixed(2)
                        ) ===
                        Math.abs(
                          parseFloat(
                            [
                              result.cashAmount,
                              result.cardAmount,
                              result.dCardAmount,
                              result.ePayAmount,
                              result.gcAmount,
                              result.onlineSales,
                              result.checkSales,
                              result.foodPandaSales,
                              result.grabSales,
                            ]
                              .filter(
                                (value) => value !== null && value !== undefined
                              )
                              .reduce(
                                (a, b) => parseFloat(a) + parseFloat(b),
                                0
                              )
                          ).toFixed(2)
                        )
                          ? "Match"
                          : "Mismatch"}
                      </td>
                    </tr>
                  ))}
                  {/* Totals */}
                  <tr style={{ fontWeight: "bold" }}>
                    <td>
                      <strong>Totals</strong>
                    </td>
                    <td>{results.length}</td>
                    <td colSpan={2}></td>
                    {/* S */}
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.grossSales || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.grossSales || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatAmount || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.vatAmount || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatableSales || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.vatableSales || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptSales || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.vatExemptSales || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptAmount || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.vatExemptAmount || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.nonvatSales || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.nonvatSales || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.snrCitDisc || 0),
                            0
                          ) -
                          Math.abs(
                            results
                              .filter((result) => result.slsFlag === "R")
                              .reduce(
                                (acc, curr) =>
                                  acc + parseFloat(curr.snrCitDisc || 0),
                                0
                              )
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.discountAmount || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.discountAmount || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {Math.abs(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.refundAmount || 0),
                            0
                          ) -
                          results
                            .filter((result) => result.slsFlag === "R")
                            .reduce(
                              (acc, curr) =>
                                acc + parseFloat(curr.refundAmount || 0),
                              0
                            )
                      ).toLocaleString()}
                    </td>
                    {/* R */}
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.grossSales * -1 || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatAmount * -1 || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatableSales * -1 || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatExemptSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatExemptAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.nonvatSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.snrCitDisc || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.discountAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.refundAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    {/* NET */}
                    {/* <td colSpan={5} style={{ backgroundColor: "#000000" }}></td> */}
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.grossSales || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.grossSales || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatableSales || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatableSales || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatAmount || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatAmount || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptSales || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptSales || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptAmount || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.vatExemptAmount || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.nonvatSales || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.nonvatSales || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.snrCitDisc || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.snrCitDisc || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {(
                        results
                          .filter((result) => result.slsFlag === "S")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.discountAmount || 0),
                            0
                          ) -
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.discountAmount || 0),
                            0
                          )
                      ).toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.cashAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.cardAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.dCardAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.ePayAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.gcAmount || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.onlineSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.checkSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.foodPandaSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td>
                      {results
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.grabSales || 0),
                          0
                        )
                        .toLocaleString()}
                    </td>
                    <td colSpan={9}></td>
                  </tr>
                  <tr>
                    <td colSpan={33}></td>
                    <td
                      colSpan={9}
                      style={{
                        textAlign: "center",
                        fontSize: "1.2em",
                        backgroundColor: "#B1A0C7",
                      }}
                    >
                      <strong>
                        {(
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.cashAmount || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.cardAmount || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.dCardAmount || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.ePayAmount || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.onlineSales || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) => acc + parseFloat(curr.gcAmount || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.checkSales || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.foodPandaSales || 0),
                            0
                          ) +
                          results.reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.grabSales || 0),
                            0
                          )
                        ).toLocaleString()}
                      </strong>
                    </td>
                    <td colSpan={9}></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiFileUploader;
